import MDRouter from "@/service/router";
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { get } from '@/utils/apiBase.js';
import lowerdev from "@/service/lowerdev";
import { env } from "md-base-tools/env";
import { mapState } from "vuex";
import { fmoney } from '@/utils/tools.js';
import { Icon, Sticky } from 'vant';
import { getCategoryTopList } from "@/api";
import Error from "@@/common/Error.vue";
export default {
  components: {
    Icon,
    Error,
    'van-sticky': Sticky
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    activeIndex() {
      let index = 0;
      this.categorytab.forEach((ele, i) => {
        if (ele.type == this.rank_type) {
          index = i;
          ele.def = 1;
        } else {
          ele.def = 0;
        }
      });
      return index;
    },
    activeSubIndex() {
      let index = 0;
      this.categorytab.forEach(ele => {
        if (ele.type == this.rank_type) {
          ele.sub_category.forEach((sub, i) => {
            if (sub.id == this.rank_cate_id) {
              index = i;
            }
          });
        }
      });
      return index;
    },
    offsetTabTop() {
      let top = 88 / 75 + 'rem';
      if (env.isModianIos() || env.isModianAndroid()) {
        top = 176 / 75 + 'rem';
      }
      return top;
    }
  },
  async setup() {
    const route = useRoute();
    const pro_category_en = route.query.pro_category_en ? route.query.pro_category_en : null;
    const rank_cate_id = route.query.rank_cate_id ? route.query.rank_cate_id : null;
    const rank_type = route.query.rank_type ? route.query.rank_type : '20';
    const title_logo = 'https://p6.moimg.net/mdwxapp/rank/' + pro_category_en + '_title.png';
    const {
      data
    } = await get('/apis/product/get_rank_category_list', {
      scene_name: 'report'
    });
    const list = await get('/apis/project/get_pro_category_top_list', {
      pro_category_en: pro_category_en,
      rank_type: rank_type,
      rank_cate_id: rank_cate_id
    });
    return {
      categorytab: ref(data),
      sub_list: ref(list.data),
      pro_category_en,
      rank_cate_id: ref(rank_cate_id),
      rank_type: ref(rank_type),
      title_logo
    };
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      loading: false
    };
  },
  async beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async getList() {
      const {
        status,
        data,
        message
      } = await getCategoryTopList({
        pro_category_en: this.pro_category_en,
        rank_type: this.rank_type,
        rank_cate_id: this.rank_cate_id
      });
      if (status == 0) {
        this.sub_list = data;
        if (document.querySelector('#containerList').querySelectorAll('.van-sticky')[0].classList.contains('van-sticky--fixed')) {
          const target = document.getElementById('containerList');
          const top = target.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: top - 40,
            behavior: 'smooth'
          });
        }
      } else {
        this.showError = true;
        this.showMessage = message;
      }
    },
    changeType(type) {
      if (this.rank_type == type) {
        return false;
      }
      this.rank_type = type;
      this.categorytab.forEach(ele => {
        if (ele.type == type) {
          this.rank_cate_id = ele.sub_category[0].id;
        }
      });
      this.getList();
    },
    changeSubType(id) {
      if (this.rank_cate_id == id) {
        return false;
      }
      this.rank_cate_id = id;
      this.getList();
    },
    back() {
      // eslint-disable-next-line
      graft.app({
        functionName: "closeController",
        functionParams: "",
        callBack: () => {},
        webFun: () => {
          window.history.go(-1);
        }
      });
    },
    goPro(pro_id) {
      MDRouter.goProInfo(pro_id);
    },
    // 千分符
    formatAmount(amount) {
      return fmoney(amount, 2).split('.')[0];
    },
    // 万
    formatMoney(amount) {
      return amount > 10000 ? (amount / 10000).toFixed(2) + '万' : amount;
    }
  }
};