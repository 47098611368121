import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4bf260d7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "empty"
};
const _hoisted_2 = {
  key: 1,
  class: "rankCategory render-hide-scroll"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "tip"
};
const _hoisted_5 = {
  class: "tab"
};
const _hoisted_6 = {
  class: "tabField"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "top"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "sub_list"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = {
  class: "cover"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  key: 0,
  class: "rank first"
};
const _hoisted_15 = {
  key: 1,
  class: "rank second"
};
const _hoisted_16 = {
  key: 2,
  class: "rank third"
};
const _hoisted_17 = {
  key: 3,
  class: "rank"
};
const _hoisted_18 = {
  class: "info"
};
const _hoisted_19 = {
  class: "title render-line-clamp"
};
const _hoisted_20 = {
  class: "detail"
};
const _hoisted_21 = {
  class: "param"
};
const _hoisted_22 = {
  key: 0,
  class: "score"
};
const _hoisted_23 = {
  key: 1,
  class: "money"
};
const _hoisted_24 = {
  key: 2,
  class: "total_money"
};
const _hoisted_25 = {
  class: "tail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_Error = _resolveComponent("Error");
  const _component_van_sticky = _resolveComponent("van-sticky");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", {
    class: _normalizeClass([{
      'headerIOS': $data.env.isModianIos()
    }, {
      'headerAndroid': $data.env.isModianAndroid()
    }])
  }, [_createVNode(_component_icon, {
    name: "https://p6.moimg.net/path/dst_project/1569452/202305/2514/2713/23052527131keNwg9RBWqElg6wGbv02PVbnJQDmX.png",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => $options.back(), ["stop"]))
  })], 2), $data.showError ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Error, {
    message: $data.showMessage
  }, null, 8, ["message"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(['cover', {
      'headerIOS': $data.env.isModianIos()
    }, {
      'headerAndroid': $data.env.isModianAndroid()
    }])
  }, [_createElementVNode("img", {
    src: `${_ctx.$imgUrlFilter($setup.title_logo, 687)}`,
    onerror: "javascript:this.src='https://p6.moimg.net/mdwxapp/rank/common_title.png'"
  }, null, 8, _hoisted_3), _createElementVNode("p", _hoisted_4, _toDisplayString($setup.categorytab && $setup.categorytab[$options.activeIndex].tip), 1), _createVNode(_component_van_sticky, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categorytab, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['type', {
          'active': $setup.rank_type == item.type
        }]),
        onClick: _withModifiers($event => $options.changeType(item.type), ["stop"])
      }, _toDisplayString(item.category_name), 11, _hoisted_7);
    }), 128))])])]),
    _: 1
  })], 2), _createElementVNode("div", {
    ref: "container",
    id: "containerList",
    class: _normalizeClass(['list', {
      'headerIOS': $data.env.isModianIos()
    }, {
      'headerAndroid': $data.env.isModianAndroid()
    }])
  }, [_createVNode(_component_van_sticky, {
    "offset-top": $options.offsetTabTop,
    container: _ctx.container
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categorytab[$options.activeIndex].sub_category, (subItem, subIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: subIndex,
        class: _normalizeClass(['sub_category', {
          'active': $options.activeSubIndex == subIndex
        }]),
        onClick: _withModifiers($event => $options.changeSubType(subItem.id), ["stop"])
      }, _toDisplayString(subItem.title), 11, _hoisted_9);
    }), 128))])]),
    _: 1
  }, 8, ["offset-top", "container"]), _createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sub_list, (subProItem, subProIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: subProIndex,
      class: "sub_item",
      onClick: _withModifiers($event => $options.goPro(subProItem.pro_id), ["stop"])
    }, [_createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
      src: `${_ctx.$imgUrlFilter(subProItem.logo || '', 400)}`
    }, null, 8, _hoisted_13), subProIndex == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_14)) : _createCommentVNode("", true), subProIndex == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_15)) : _createCommentVNode("", true), subProIndex == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_16)) : _createCommentVNode("", true), subProIndex > 2 ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("span", null, _toDisplayString(subProIndex + 1), 1)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("p", _hoisted_19, _toDisplayString(subProItem.short_title), 1), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [$setup.rank_type == '20' ? (_openBlock(), _createElementBlock("div", _hoisted_22, "热度值" + _toDisplayString($options.formatMoney(subProItem.score)), 1)) : _createCommentVNode("", true), $setup.rank_type == '20' ? (_openBlock(), _createElementBlock("div", _hoisted_23, "已筹¥" + _toDisplayString($options.formatAmount(subProItem.backer_money)), 1)) : _createCommentVNode("", true), $setup.rank_type == '30' ? (_openBlock(), _createElementBlock("div", _hoisted_24, "已筹¥" + _toDisplayString($options.formatAmount(subProItem.backer_money)), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_25, [_createElementVNode("p", null, _toDisplayString(subProItem.backer_count) + "人支持", 1), _createElementVNode("p", null, "进度" + _toDisplayString(subProItem.progress), 1), _createElementVNode("div", {
      class: _normalizeClass(['status', {
        'ing': subProItem.status_code == 0
      }])
    }, _toDisplayString(subProItem.pro_status), 3)])])])], 8, _hoisted_11);
  }), 128))])], 2)]))], 64);
}